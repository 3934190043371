import { render, staticRenderFns } from "./mx-select.vue?vue&type=template&id=a1b8063e&scoped=true&"
import script from "./mx-select.vue?vue&type=script&lang=js&"
export * from "./mx-select.vue?vue&type=script&lang=js&"
import style0 from "./mx-select.vue?vue&type=style&index=0&id=a1b8063e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a1b8063e",
  null
  
)

export default component.exports