<template>
    <div class="loc-wrapper">
        <div class="loc-widget-label">
            {{title}}
        </div>
        <div class="loc-widget-value">
            {{address?address:'--'}}
        </div>
    </div>
</template>
<script>
export default {
    name: 'MXLocGeocoder',
    components: {

    },
    props: {
        locInfo: {
            type: Object,
        },
        title:{
            type:String
        }
    },
    data() {
        return {
            address:'',
        }
    },
    mounted() {},
    methods: {
        parseLocationAddress(lon, lat) {
            if (lon && lat) {
                var point = new BMap.Point(lon, lat)
                let gc = new BMap.Geocoder()
                gc.getLocation(point, rs => {
                    this.address = rs.address
                })
            }
        },
    },
    watch: {
        locInfo: {
            handler(val) {
                if (val && val['lon'] && val['lat'])
                this.parseLocationAddress(val['lon'],val['lat'])
            },
            immediate: true,
        },
    }

}
</script>
<style lang="scss" scoped>
.loc-wrapper {
    display: flex;
    justify-content: space-between;
}

.loc-widget-label {
  padding-bottom: 4px;
  color: #525967;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  word-wrap: break-word;
  word-break: break-word;
}

.loc-widget-value {
  color: #141e31;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-wrap;
  background: none !important;
  border-radius: 2px;
  word-wrap: break-word;
  word-break: break-word;
}
</style>