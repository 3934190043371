<template>
  <div>
    <el-input
      v-model="queryParams[conf.key]"
      v-if="conf.typeUi == 'el-input'"
      size="small"
      :placeholder="'请输入' + conf.label"
      clearable
      @input="changeValue"
    />

    <el-select
      v-model="queryParams[conf.key]"
      v-if="conf.typeUi == 'el-select'"
      :placeholder="'请选择' + conf.label"
      clearable
      @change="changeValue"
    >
      <el-option
        v-for="(item, index) in options"
        :key="index"
        :value="item.value"
        :label="item.label"
      />
    </el-select>

    <el-date-picker
      @change="changeValue"
      v-if="conf.typeUi == 'el-date-picker'"
      v-model="queryParams[conf.key]"
      style="width: 240px"
      value-format="yyyy-MM-dd HH:mm:ss"
      type="daterange"
      range-separator="-"
      :start-placeholder="conf.label"
      end-placeholder="结束日期"
      :default-time="['00:00:00', '23:59:59']"
    />
  </div>
</template>

<script>
const attributeMap = {
  "contract-project-list": {
    label: "projectName",
    value: "id",
  },
  "assign-user-list": {
    label: "nickname",
    value: "id",
  },
  "common-data-list": {
    label: "label",
    value: "value",
  },
  "project-leader": {
    label: "nickname",
    value: "id",
  },
  "contract-leader": {
    label: "nickname",
    value: "id",
  },
  "contract-customer-list": {
    label: "customerName",
    value: "id",
  },
  "department-list": {
    label: "name",
    value: "id",
  },
  "project-elevator-list": {
    label: "elevatorCode",
    value: "id",
  },
};

// import request from "@/utils/request";
export default {
  props: {
    conf: Object,
    queryParams: Object,
  },
  data() {
    return {
      options: [],
      currentApi: "",
    };
  },
  created() {
    console.log(this.conf,'this.confthis.conf');
    if (this.conf.api) {
      this.getDataByApi(this.conf.api);
    }
  },
  methods: {
    changeValue(e) {
      this.$emit("changeValue", {
        queryParams: this.queryParams,
      });
    },
    // getDataByApi(api) {
    //   console.log(api,'api');
    //   this.currentApi = api;
    //   let _url = process.env.VUE_APP_BASE_API + "/" + api;
    //   request({
    //     url: _url,
    //     method: "GET",
    //     params: {},
    //   }).then((response) => {
    //     this.options = this.parseListWithResponse(response);
    //   });
    // },
    parseListWithResponse(response) {
      let datakeys = this.conf.dataKey.split(".");
      if (datakeys.length) {
        let target = response;
        datakeys.forEach((key) => {
          target = target[key];
        });
        if (this.conf.dataLabel && this.conf.dataValue) {
          let array = target.map((item) => {
            return {
              label: item[this.conf.dataLabel],
              value: item[this.conf.dataValue],
            };
          });
          return array;
         
        } else {
          return target;
        }
      } else {
        return response.data.list;
      }
    },
    reloadDataByApi(api) {
      this.$nextTick(() => {
        this.currentApi = api;
        this.getDataByApi(api);
      });
    },
  },
  watch: {},
};
</script>

<style>
</style>