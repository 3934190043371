<template>
  <div class="data-widget">
    <div class="widget-label">{{label}}</div>
    <div class="widget-value">
      <div style="display:flex; justify-content: flex-start;">
        <div>
          <el-input v-model="longitude" :disabled="true" placeholder="经度"></el-input>
        </div>
        <div style="margin-left:20px">
          <el-input v-model="latitude" :disabled="true" placeholder="纬度"></el-input>
        </div>
        <div style="margin-left: 20px;" v-if="!disabled">
          <el-button size="small" type="primary" plain @click="showMapPicker = true">地图选点</el-button>
        </div>
      </div>
    </div>
    <!-- <BaiduMapPicker :show.sync="showMapPicker" :lng="longitude" :lat="latitude" @change="onMapChange"></BaiduMapPicker> -->
  </div>
</template>
<script>
// import { getAccessToken } from "@/utils/auth";
// import BaiduMapPicker from '@/components/Map/baiduMapPicker'

export default {
  name: 'MXAttachment',
  components: {
    // BaiduMapPicker
  },
  props: ['label', 'value', 'identifier', 'disabled', 'required'],
  data() {
    return {
      showMapPicker: false,
      address: '',
      longitude: '',
      latitude: ''
    }
  },
  methods: {
    onMapChange(e) {
      this.longitude = e.lng
      this.latitude = e.lat

      if (e.useAddress && e.address) {
        this.address = e.address
        this.$emit('onGetMapLocation', e)
      }
    },
  },
  watch: {
    'value': {
      handler: function(val) {
        if (val) {
          console.log(val,'valvalval');
          this.latitude = val.split('#')[0]
          this.longitude = val.split('#')[1]
        }
      },
      immediate: true,
    }
  }
}

</script>
<style lang="scss" scoped>
.widget-value {
  min-height: 32px;
  padding: 6px 0px;
  color: #141e31;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-wrap;
  background: none !important;
  border-radius: 2px;
  word-wrap: break-word;
  word-break: break-word;
}

.file-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.file-uploader .el-upload:hover {
  border-color: #409EFF;
}

.file-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  //width: 178px;
  //height: 178px;
  //line-height: 178px;
  text-align: center;
}

.file {
  width: 178px;
  height: 178px;
  display: block;
}

</style>
