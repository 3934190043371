<template>
  <div class="data-widget">
    <div class="widget-label">{{ label }}</div>
    <div class="widget-value">
      <el-upload
        list-type="picture-card"
        class="file-uploader"
        :action="uploadAction"
        :show-file-list="true"
        :on-error="handleFileerror"
        :on-success="handleFileSuccess"
        :before-upload="beforeFileUpload"
        :on-remove="onFileRemove"
        :headers="headers"
        :multiple="true"
        :file-list="fileList"
      >
        <i slot="default" class="el-icon-plus"></i>
        <div slot="file" slot-scope="{ file }">
          <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
          <span class="el-upload-list__item-actions">
            <span
              class="el-upload-list__item-delete"
              @click="handleDownload(file)"
            >
              <i class="el-icon-download"></i>
            </span>
            <span
              class="el-upload-list__item-delete"
              @click="handleRemove(file)"
            >
              <i class="el-icon-delete"></i>
            </span>
          </span>
        </div>
      </el-upload>
    </div>
  </div>
</template>
<script>
// import { getAccessToken } from "@/utils/auth";

export default {
  name: "MXAttachment",
  components: {},
  props: ["label", "identifier", "value"],
  data() {
    return {
      uploadAction:
        process.env.VUE_APP_BASE_API + "/admin-api/infra/file/upload",
      headers: { Authorization: "Bearer " + getAccessToken() },
      fileList: [],
      fileParams: [],
    };
  },
  methods: {
    handleRemove(file) {
      this.fileList =this.fileList.filter(item=>{
        return item.uid!=file.uid
      }) 
      this.fileParams = this.fileList;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      window.open(file.url)
    },
    handleFileSuccess(response, file, list) {
      let arr = new Array();
      list.forEach((item, index) => {
        arr[index] = {
          ownerId: "xxx",
          ownerType: "ElevatorDO",
          type: 1,
          url: item.response ? item.response.data.url : item.url,
          uid: file.uid,
        };
      });

      this.fileParams = arr;
    },
    beforeFileUpload(file) {
      const fileType = file.name.split(".").pop().toLowerCase();
      const allowedTypes = ["xls", "doc", "jpg", "pdf", "ppt"];
      if (!allowedTypes.includes(fileType)) {
        this.$message.error("只能上传xls、doc、jpg、pdf和ppt格式的文件");
        return false; // 取消上传
      }
      return true; // 允许上传
    },
    handleFileerror() {
      this.$message.error("文件上传失败");
    },
    onFileRemove(file, list) {
      let arr = new Array();

      list.forEach((item, index) => {
        arr[index] = {
          ownerId: "9999",
          ownerType: "ElevatorDO",
          type: 1,
          url: item.response ? item.response.data.url : item.url,
          uid: file.uid,
        };
      });

      this.fileParams = arr;
    },
  },
  watch: {
    fileParams: {
      handler(event) {
        console.log(event);
        this.$emit("select", {
          identifier: this.identifier,
          value: event,
        });
      },
      immediate: false,
    },
    value: {
      handler(val) {
        if (val && val.length > 0) {
          val.forEach((item) => {
            this.fileList.push({
              url: item,
            });
          });
        } else {
          this.fileList = [];
        }
      },
      immediate: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.widget-value {
  min-height: 32px;
  padding: 6px 0px;
  color: #141e31;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-wrap;
  background: none !important;
  border-radius: 2px;
  word-wrap: break-word;
  word-break: break-word;
}

.file-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.file-uploader .el-upload:hover {
  border-color: #409eff;
}

.file-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  //width: 178px;
  //height: 178px;
  //line-height: 178px;
  text-align: center;
}

.file {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
