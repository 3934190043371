<template>
  <div class="data-widget">
    <div class="widget-label"></div>
    <div class="widget-value">
      <div class="action-btn-wrapper">
        <el-button icon="el-icon-office-building" size="large" @click="onSelectElevator">选择电梯</el-button>
      </div>
      <div class="widget-wrapper flex flex-wrap">
        <div class="line-wrapper width-50 padding-lr-10" v-for="(item, index) in dataList">
          <MXInfoParser :item="item" />
        </div>
      </div>
    </div>
    <el-dialog title="选择电梯" :visible.sync="elevatorDialogShow" append-to-body>
      <searchbar :addShow="false" :importShow="false" :exportShow="false" ref="searchBar"  @queryTable="getList" :tableColumns="tableColumns" :queryParams='queryParams' />
      <MXTable :loading='loading' :list="list" :tableColumns="tableColumns" :total="total" :formItem="queryParams" @search="getList" :singleSelection="true" @onCurrentChange="onCurrentChange"></MXTable>
    </el-dialog>
  </div>
</template>
<script>
// import { datasInstance } from '@/pages/elevator/sel-datas'
import searchbar from '@/components/SearchBar';
// import { getElevatorPage, exportElevatorExcel } from "@/api/elevator/elevator";
import MXTable from '@/pages/components/mx-ui-components/mx-table'
import MXInfoParser from '@/pages/components/mx-ui-components/mx-info-parser'
export default {
  name: 'MXElevatorSelector',
  components: {
    searchbar,
    MXInfoParser,
    MXTable
  },
  props: [],
  data() {
    return {
      dataList: [],
      tableColumns: datasInstance().getTableColumns(),
      elevatorDialogShow: false,
      loading:false,
      queryParams: {
      },
      list:[],
      total:0,
      selectRow:null,
    }
  },
  mounted() {
    this.dataList = this.defaultDataList()
  },
  methods: {
    getList() {
      this.loading = true;
      // 执行查询
      getElevatorPage(this.queryParams).then(response => {
        this.list = response.data.list;
        this.total = response.data.total;
        this.loading = false;
      });
    },
    onSelectElevator() {
      this.list = []
      this.elevatorDialogShow = true
      this.$nextTick(()=> {
        this.getList()
      })
    },
    onCurrentChange(val) {
      let array = this.dataList
      array.forEach((item)=> {
        if (val[item.key])
        item['value'] = val[item.key]
      })
      this.selectRow = val
      this.dataList = JSON.parse(JSON.stringify(array))
      this.elevatorDialogShow = false
      this.$emit('selectElevator',this.selectRow)
    },
    defaultDataList() {
      return [
        {
          'key': 'elevatorCode',
          'label': '电梯编号',
          'ui': 'mx-text',
          typeUi:'el-input',
        },
        {
          'key': 'registrationCode',
          'label': '注册代码',
          'ui': 'mx-text',
        },
        {
          'key': 'manufactoryCode',
          'label': '出厂编号',
          'ui': 'mx-text',
        },

        {
          'key': 'elevatorName',
          'label': '电梯名称',
          'ui': 'mx-text',
        },
      ]
    },
  },
  watch: {
    'queryParams.stationId':function(newObj){
      // let itemParser = this.$refs.searchBar.find((itemParser) => {
      //   return itemParser.item.key == 'contractProjectId' && itemParser.item.typeUi == 'el-select'
      // })
      this.$refs.searchBar.reloadApiByParams('admin-api/shmashine-contract/contract-project/page?pageNo=1&pageSize=100', {
          stationId: newObj
        })
    }
  }

}

</script>
<style lang="scss" scoped>
.line-wrapper {
  margin-bottom: 10px;
}

.action-btn-wrapper {
  margin-bottom: 10px;
}

</style>
