<template>
  <div class="data-widget">
    <div class="widget-label">{{label}}<span v-if="required" style="color:red; line-height: 22px;">*</span></div>
    <div class="widget-value">
        <el-input v-model="inputValue" :disabled="disabled" placeholder="请输入内容"></el-input>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MXInput',
  components: {},
  props: ['label', 'value', 'identifier', 'disabled', 'required'],
  data() {
    return {
      inputValue: '',
    }
  },
  watch: {
    'inputValue': {
      handler: function(val) {
        this.$emit('input', {
          identifier: this.identifier,
          value: val
        })
      }
    },
    'value': {
      handler: function(val) {
        this.inputValue = val
      },
      immediate: true,
    }
  }
}

</script>
<style lang="scss" scoped>
.widget-label {
  padding-bottom: 4px;
  color: #525967;
  font-weight: 500;
  font-size: 14px;
  word-wrap: break-word;
  word-break: break-word;
}

.widget-value {
  min-height: 32px;
  color: #141e31;
  font-size: 14px;
  white-space: pre-wrap;
  background: none !important;
  border-radius: 2px;
  word-wrap: break-word;
  word-break: break-word;
}

</style>
