<template>
  <div>
    <MXText :label="item.label" :value="item.value" v-if="item.ui == 'mx-text'" :showSlot="item.showSlot">
      <dict-tag v-if="item.type == 'dict'" :type="item.dictType" :value="item.value" />
    </MXText>
    <MXTextArea :label="item.label" placeholder="暂无内容" :disabled="true" :value="item.value" v-if="item.ui == 'mx-textarea'"/>
    <MXSelect :disabled="true" :identifier="item.key" :label="item.label" :value="item.value" :dataKey="item.dataKey" :dataLabel="item.dataLabel" :dataValue="item.dataValue" v-if="item.ui == 'mx-select'" :api="item.api" />
    <MXAttachment  :label="item.label" :value="item.value" v-if="item.ui == 'mx-attachment'" />
    <MXLocation :disabled="true"  :label="item.label" :value="item.value" v-if="item.ui == 'mx-location'" />
  </div>
</template>
<script>
import MXLocation from '@/pages/components/mx-ui-components/mx-location'
import MXAttachment from '@/pages/components/mx-ui-components/mx-attachment'
import MXTextArea from '@/pages/components/mx-ui-components/mx-textarea'
import MXText from '@/pages/components/mx-ui-components/mx-text'
import MXSelect from '@/pages/components/mx-ui-components/mx-select'
export default {
  name: 'MXInfoParser',
  components: {
    MXText,
    MXSelect,
    MXTextArea,
    MXAttachment,
    MXLocation
  },
  props: ['item'],
  data() {
    return {

    }
  },

}

</script>
<style lang="scss" scoped>
</style>
