<template>
    <div class="boxes-wrapper">
        <div class="box" v-for="box in boxes">
            <div class="box-item" :class="index == 0?' left':' right'" v-for="(item,index) in box"><span class="info-widget-label">{{item.label}}:</span> <span class="info-widget-value"> {{ item.value }} </span> </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'MXDoubleInfo',
    components: {

    },
    props: {
        items: {
            type: Array,
        }
    },
    data() {
        return {
            boxes: [],
        }
    },
    mounted() {},
    methods: {
        updateBoxes(items) {
            if (items.length) {
                let idx = 0
                let array = []
                while (idx < items.length) {
                    array.push(items.slice(idx, idx + 2))
                    idx = idx + 2
                }
                this.boxes = array
            } else {
                this.boxes = []
            }
        },
    },
    watch: {
        items: {
            handler(val) {
                this.updateBoxes(val)
            },
            immediate: true,
        },
    }

}
</script>
<style lang="scss" scoped>
.boxes-wrapper {
    padding: 10px 0;
}

.box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.box:last-of-type {
    margin-bottom:0px;
}

.box-item span {
    margin-left: 4px;
}


.left {
    text-align: left;
}

.right {
    text-align: right;
}

.info-widget-label {
  padding-bottom: 4px;
  color: #525967;
  font-weight: 500;
  font-size: 14px;
  word-wrap: break-word;
  word-break: break-word;
}

.info-widget-value {
  color: #141e31;
  font-size: 14px;  
  white-space: pre-wrap;
  background: none !important;
  border-radius: 2px;
  word-wrap: break-word;
  word-break: break-word;
}
</style>