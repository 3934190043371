<template>
  <div class="data-widget">
    <div class="widget-label">{{label}}</div>
    <div class="widget-value-bg">
      <slot v-if="showSlot"/>
      <div v-else :class="!value || value.length <= 0?'empty-text':''">{{value?value:'暂无内容'}}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MXText',
  components: {},
  props: ['label', 'value','showSlot'],
  data() {
    return {

    }
  },

}

</script>
<style lang="scss" scoped>
.data-widegt {}
.empty-text {
  color:#b5b8be;
}

</style>
