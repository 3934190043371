<template>
    <div>
        <!-- 列表 -->
        <el-table :disabled="readonly" ref="table" resizable v-loading="loading" :data="list" @row-click="onTableRowClick" :highlight-current-row="singleSelection?true:false" @current-change="onCurrentChange" @selection-change="onSelectionChange" @select="onSelect" @select-all="onSelectAll">
            <el-table-column v-for="(item,index) in tableColumnsData" :label="item.label" :type="item.selection?'selection':''" :prop="item.key" :width="item.width" align="center" :show-overflow-tooltip="true" :fixed="item.fixed">
                <template v-slot="scope" v-if="item.type == 'dict'">
                    <dict-tag :type="item.dictType" :value="scope.row[item.key]" />
                </template>
                <template v-slot="scope" v-else-if="item.type == 'datetime'">
                    {{parseTime(scope.row[item.key],'{y}-{m}-{d} {h}:{i}:{s}')}}
                </template>
                <template v-slot="scope" v-else-if="item.type == 'date'">
                    {{parseTime(scope.row[item.key],'{y}-{m}-{d}')}}
                </template>
                <template slot-scope="scope" v-else-if="item.type=='custom'">
                    {{item.customRender(scope.row)}}
                </template>
                <template slot-scope="scope" v-else-if="item.type=='customRender'">
                    <MXRenderColumn :scope="scope" :render="item.render" />
                </template>
                <template slot-scope="scope" v-else-if="item.type=='action'">
                    <MXRenderColumn :scope="scope" :render="item.render" />
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页组件 -->
        <pagination v-if="showPagination" v-show="total > 0" :total="total" :page="pageNo" :limit="pageSize" @pagination="onPageChange" />
    </div>
</template>
<script>
import MXRenderColumn from '@/pages/components/mx-ui-components/mx-render-column'
export default {
    name: 'MXTable',
    components: {
        MXRenderColumn
    },
    props: {
        formItem: {
            // 查询条件
            type: Object,
            require: true,
        },
        PageShowFlagVal: {
            // 查询条件
            type: Boolean,
            default: true,
        },
        list: {
            // 当前页的数据
            type: Array,
            required: true,
        },
        tableColumns: {
            // 当前表格配置
            type: Array,
            required: true,
        },
        loading: {
            // 加载中
            type: Boolean,
            default: false,
        },
        total: {
            // 总条数
            type: Number,
            required: true,
        },
        singleSelection: {
            // 选择
            type: Boolean,
            default: false,
        },
        showOperate: {
            // 操作
            type: Boolean,
            default: false,
        },
        showPagination: {
            // 显示分页
            type: Boolean,
            default: true,
        },
        readonly: {
            // 显示分页
            type: Boolean,
            default: false,
        },
    },
    // props: ['list', 'tableColumns', 'loading', 'total', 'pageNo', 'pageSize', 'singleSelection', 'showOperate'],
    data() {
        return {
            pageNo: 1,
            pageSize: 10,

            tableColumnsData: []
        }
    },
    created() {
        // 将页面中的查询对象中加入 分页条件
        if (this.PageShowFlagVal) {
            if (this.formItem) {
                this.formItem["pageNo"] = this.pageNo;
                this.formItem["pageSize"] = this.pageSize;
            }
        }
        this.tableColumnsData = this.tableColumns.filter((item) => {
            return item.isTable != false;
        });
    },
    methods: {
        resetPage() {
            this.pageNo = 1
            this.pageSize = 10

            if (this.PageShowFlagVal) {
                if (this.formItem) {
                    this.formItem["pageNo"] = this.pageNo;
                    this.formItem["pageSize"] = this.pageSize;
                }
            }
        },

        toggleRowSelection(row, selection) {
            this.$refs.table.toggleRowSelection(row, selection)
        },
        onPageChange(e) {
            let pageNo = e.page
            let pageSize = e.limit
            if (this.PageShowFlagVal) {
                this.pageNo = pageNo || this.pageNo;
                this.pageSize = pageSize || this.pageSize;
                this.formItem["pageNo"] = this.pageNo;
                this.formItem["pageSize"] = this.pageSize;
            }
            this.$emit("search");
        },
        onTableRowClick(row, column, event) {
            if (this.showOperate) return
            this.$emit('onTableRowClick', row)
        },
        onCurrentChange(val) {
            this.$emit('onCurrentChange', val)
        },
        onSelectionChange(selection, row) {
            this.$emit('selectionChange', selection, row)
        },
        onSelect(selection, row) {
            this.$emit('select', row)
        },
        onSelectAll(selection) {
            this.$emit('selectAll', selection)
        },
        reload() {
            this.$refs.table.doLayout()
        },
    },
    watch: {
        formItem: {
            handler: function() {
                this.pageNo = 1
                this.formItem["pageNo"] = 1;
            },
            deep: true,
        },
        'list'(val) {
            if (val.length == 0 && this.pageNo != 1) {
                this.pageNo = this.pageNo - 1
                this.formItem["pageNo"] = this.pageNo;
                this.$emit("search");
            }
        },
        'tableColumns'(val) {
            this.tableColumnsData = this.tableColumns.filter((item) => {
                return item.isTable != false;
            });
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/common/styles/handle.scss';

::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
    border: none;
}

.el-table ::v-deep th.el-table__cell>.cell {
    @include font_color('tableHeaderTextColor');
}

.el-table {
    ::v-deep .el-table__cell {
        padding: 8px 0;
        @include font_color('tableCellTextColor');
        @include background_color('tableCellBgColor');
    }
}

.el-table {
    ::v-deep .el-table__empty-block {
        @include background_color('tableEmptyBgColor');
    }
}

.el-table {
    ::v-deep th.el-table__cell.is-leaf {
        @include border_color(seperatorColor);
    }
}

::v-deep .el-table--border::after,
.el-table--group::after,
.el-table::before {
    background-color: transparent !important;
}

::v-deep .el-table__fixed-right::before,
::v-deep .el-table__fixed::before {
    background-color: transparent !important;
}

::v-deep.ivu-modal-content {
    background-color: #f2f7fb;
}

::v-deep .ivu-modal-header {
    border: none;
}

::v-deep.ivu-modal-body {
    padding-top: 0;
}

.ivu-select,
.ivu-date-picker {
    margin-right: 10px;
}
</style>