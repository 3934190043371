<template>
  <div>
    <el-row :gutter="10">
      <!-- 搜索工作栏 -->
      <el-col :span="18">
        <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" label-width="68px">
          <!-- 默认搜索框 -->
          <el-form-item v-for="(item, index) in tableColumnsArr" :key="index">
            <formItem ref="formItemData" :queryParams="queryParams" @changeValue="getQueryParams" :conf="item">
            </formItem>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" icon="el-icon-search" @click="handleQuery">搜索</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <div class="top-right-btn">
        <el-row :gutter="10">
          <el-col :span="1.5">
            <!--  <el-select filterable :filter-method="dataFilter" multiple placeholder="请选择">
            <el-option>
              <span style="float: left"> 搜索全部内容 </span>
              <span style="float: right"><i class="el-icon-check"></i></span>
            </el-option>
            <el-option>
              <span style="float: left">搜索特定字段</span>
              <span v-if="flag==0" @click="flag=1,show=true" style="float: right"><i class="el-icon-arrow-up"></i></span>
              <span v-if="flag==1" @click="flag=0,show=false" style="float: right"><i class="el-icon-arrow-down"></i></span>
            </el-option>
            <template v-if="show">
              <el-option
                v-for="item in cities"
                :key="item.value"
                :label="item.label"
              >
              <el-checkbox>{{ item.label }}</el-checkbox>
              </el-option>
            </template>
          </el-select> -->
          </el-col>
          <!--  <el-col :span="1.5">
            <el-tooltip effect="dark" content="筛选条件" placement="top">
              <el-button size="small" circle icon="el-icon-share" />
            </el-tooltip>
          </el-col>
          <el-col :span="1.5">
            <el-tooltip effect="dark" content="显示字段" placement="top">
              <el-button
                size="small"
                circle
                icon="el-icon-c-scale-to-original"
              />
            </el-tooltip>
          </el-col> -->
          <el-col :span="1.5">
            <el-tooltip effect="dark" content="刷新" placement="top">
              <el-button size="small" circle icon="el-icon-refresh" @click="refresh()" />
            </el-tooltip>
          </el-col>
        </el-row>
      </div>
    </el-row>
    <el-row :gutter="10" class="searchbar">
      <el-col :span="1.5">
        <el-button type="primary" icon="el-icon-plus" size="small" @click="handleAdd" v-if="addShow">新增</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="primary" icon="el-icon-download" size="small" @click="handleGennerateCode" v-if="generateCodeShow">生成二维码</el-button>
      </el-col>
      <!--    <el-col :span="1.5">
        <el-button v-if="importShow"  icon="el-icon-download" size="small">导入</el-button>
      </el-col>

      <el-col :span="1.5">
        <el-button v-if="exportShow "  icon="el-icon-upload2" size="small" @click="handleExport"
          >导出</el-button
        >
      </el-col> -->
    </el-row>
  </div>
</template>
<script>
import formItem from "./item/formItem";
export default {
  props: {
    tableColumns: Array,
    queryParams: Object,
    addShow: {
      type: Boolean,
      default: true
    },
    importShow: {
      type: Boolean,
      default: true
    },
    exportShow: {
      type: Boolean,
      default: true
    },
    generateCodeShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false,
      cities: [{
          value: "Beijing",
          label: "北京",
        },
        {
          value: "Shanghai",
          label: "上海",
        },
        {
          value: "Nanjing",
          label: "南京",
        },
        {
          value: "Chengdu",
          label: "成都",
        },
        {
          value: "Shenzhen",
          label: "深圳",
        },
        {
          value: "Guangzhou",
          label: "广州",
        },
      ],
      show: true,
      value: "",
      flag: 0,
      tableColumnsArr: [],
      id: "",
      // checked:0
    };
  },
  created() {
    console.log(this.addShow, 'addShow');
    this.tableColumnsArr = this.tableColumns.filter((item) => {
      return item.default;
    });
  },
  components: { formItem },
  methods: {
    // 新增
    handleAdd() {
      this.$emit("handleAdd");
    },

    // 导出
    handleExport() {
      // console.log(123);
      this.$emit("handleExport");
    },
    // 刷新
    refresh() {
      this.$emit("queryTable");
    },
    // 搜索
    dataFilter() {
      // console.log(123);
    },
    // 搜索
    handleQuery() {
      this.$emit("queryTable");
    },
    handleGennerateCode() {
      this.$emit("generateCode");
    },
    getQueryParams(e) {
      // this.$emit("queryTable");
    },

    reloadApiByParams(api, params) {
      let newApi = this.generateApi(api, params);
      let itemParser = this.$refs.formItemData.find((itemParser) => {
        return itemParser.conf.linkage
      })
      itemParser.reloadDataByApi(newApi)
      // this.$nextTick(() => {
      //   this.tableColumnsArr.forEach((item, index) => {
      //     this.$refs.formItemData[index].reloadDataByApi(newApi);
      //   });
      // });
    },
    generateApi(target, params) {
      let url = target.split("?").length ? target.split("?")[0] : target;
      let queryParams = this.queryURLParams(target);
      let sub = this.jsontoQuery(Object.assign(params, queryParams));
      if (sub.length) url = url + "?" + sub;
      return url;
    },
    queryURLParams(url) {
      let pattern = /(\w+)=(\w+)/gi; //定义正则表达式
      let parames = {}; // 定义参数对象
      url.replace(pattern, ($, $1, $2) => {
        parames[$1] = $2;
      });
      return parames;
    },
    jsontoQuery(json) {
      let str = "";
      let query = "";
      for (let i in json) {
        // i是对象的键值
        str += i + "=" + json[i] + "&"; // json[i][j]是属性值
      }
      query = str.substring(0, str.length - 1);
      return query;
    },
  },
};

</script>
<style lang="scss" scoped>
.searchbar {
  margin-bottom: 10px;
}

::v-deep.el-input--mini .el-input__inner {
  width: 140px;
  border-radius: 18px;
  background-color: #f0f1f4;
}

</style>
