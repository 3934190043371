<template>
  <div class="data-widget">
    <div class="widget-label">{{label}}</div>
    <div class="widget-value">
      <el-input :disabled="disabled" type="textarea" :rows="4" :placeholder="placeholder?placeholder:'请输入'" v-model="inputValue">
      </el-input>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MXTextArea',
  components: {},
  props: ['label', 'value', 'identifier', 'disabled', 'required','placeholder'],
  data() {
    return {
      inputValue: '',
    }
  },
  watch: {
    'inputValue': {
      handler: function(val) {
        this.$emit('input', {
          identifier: this.identifier,
          value: val
        })
      }
    },
    'value': {
      handler: function(val) {
        this.inputValue = val
      },
      immediate: true,
    }
  }

}

</script>
<style lang="scss" scoped>
.data-widegt {}

.empty-text {
  color: #b5b8be;
}

</style>
